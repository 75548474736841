import React from 'react'
import Header from './Header'
import LeftSideBar from './LeftSideBar'
import Footer from './Footer'

const MainLayout = ({ children }) => {
    return (
        <div className="page-wrapper">
            {/* start header */}
            <Header />
            {/*  end header */}
            {/* start page container  */}
            <div className="page-container">
                {/* start sidebar menu  */}
                <LeftSideBar />
                {/* <!-- end sidebar menu --> */}
                {/* <!-- start page content --> */}
                <div className="page-content-wrapper">
                    {children}
                </div>
                {/* <!-- end page content --> */}
            </div>
            {/* <!-- start footer --> */}
            <Footer />
            {/* <!-- end footer --> */}
        </div>
    )
}

export default MainLayout
