import CustomAxios from "../utils/CustomAxios";


const Api = () => {
    const axiosForJSON = CustomAxios('application/json');
    // const axiosForMultipart = CustomAxios('multipart/form-data');
    const ParsingGetAll = () => {
        return axiosForJSON.get("/parsings");
    };

    const ParsingGetSpecific = (id) => {
        return axiosForJSON.get(`/parsings/${id}`);
    };

    const ParsingCreate = (data) => {
        return axiosForJSON.post("/parsings/create", data);
    };

    const ParsingUpdate = (id, data) => {
        return axiosForJSON.put(`/parsings/${id}`, data);
    };

    const ParsingSingleRemove = (id) => {
        return axiosForJSON.delete(`/parsings/${id}`);
    };

    const ParsingRemoveAll = () => {
        return axiosForJSON.delete(`/parsings/delete-all`);
    };
    const ContactEmailsGetAll = () => {
        return axiosForJSON.get("/cron/contact-emails-list/");
    };
    const ContactEmailSendMsg = (data) => {
        return axiosForJSON.post("/cron/contact-emails-msg-send/",data);
    };
    const ContactEmailsParsing= () => {
        return axiosForJSON.get("/cron/contact-mails-parsing");
    };


    const Api_list = {
        ParsingGetAll,
        ParsingGetSpecific,
        ParsingCreate,
        ParsingUpdate,
        ParsingSingleRemove,
        ParsingRemoveAll,
        ContactEmailsGetAll,
        ContactEmailSendMsg,
        ContactEmailsParsing,
    }
    return Api_list;
}




export default Api;