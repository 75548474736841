import React from 'react'

const Footer = () => {
    return (
        //{/* start footer */}
        <div className="page-footer">
            <div className="page-footer-inner">
                Pixeltre Solutions
            </div>
            <div className="scroll-to-top">
                <i className="material-icons">eject</i>
            </div>
        </div>
        //{/* end footer */}
    )
}

export default Footer
